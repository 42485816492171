<template>
  <div class="row m-0 me-2 ms-2 mb-5">
    <div class="accordion accordion-flush p-0" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        Ιστορία
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <MarkdownItVue :content="history" class="mt-3"/>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        Συμβούλιο
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body m-0 p-0">
        <Board />
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
        Αλληλογραφία 
      </button>
    </h2>
    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body m-0 p-0">
        <ul class="mt-4">
          
          <li> <a href="https://docs.google.com/document/d/1W21ngwvGFWWKxLX4VsGh3XtLx4V7Z5i2/edit?usp=sharing&ouid=103440059336710073483&rtpof=true&sd=true">Φευρουάριος 2024</a></li>
          <li> <a href="https://docs.google.com/document/d/177ke5LSI45B3N7_ViYNfeO3kxSLD0ZIm/edit?usp=sharing&ouid=103440059336710073483&rtpof=true&sd=true">Δεκέμβριος 2024</a></li>
          <li> <a href="https://docs.google.com/document/d/1LM6WMpVvMGo0ETeqPDJSpX-9hmGLMdHy/edit?usp=sharing&ouid=108862183518587906803&rtpof=true&sd=true">Απρίλιος 2024</a></li>
          <li> <a href="https://docs.google.com/document/d/1AMO3LmZF4feMGrBEhTkDm3VcWbV4rrEx/edit?usp=drive_link&ouid=108862183518587906803&rtpof=true&sd=true">Μάρτιος 2024</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'
import markdown from "raw-loader!../assets/History.md";
import Board from '../components/Board'
export default {
  data() {
    return {
      history: markdown
    }
  },
  components: {
    Board,
    MarkdownItVue
  }
}
</script>
